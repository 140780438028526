<!-- 党组织架构图弹窗页面 -->
<template>
  <el-dialog
    title="组织架构图"
    :visible.sync="visible"
    width="50%"
    :z-index="99"
    :close-on-click-modal="false"
    :show-close="true"
  >
    <a-card
      style="height: 500px; width: 100%; overflow: hidden"
      class="_page-content"
    >
      <div class="department-outer">
        <div class="zoom-box">
          <zoom-controller
            v-model="zoom"
            :min="20"
            :max="200"
          ></zoom-controller>
        </div>
        <div class="view-box">
          <org-view
            :data="data"
            :zoom-handled="zoomHandled"
            @on-menu-click="handleMenuClick"
          ></org-view>
        </div>
      </div>
    </a-card>
  </el-dialog>
</template>

<script>
import { initGridFormData } from "@/common/tools";
import OrgView from "./org-tree/org-view.vue";
import ZoomController from "./org-tree/zoom-controller.vue";
import './org-tree/index.less'
import api from "./api";
export default {
  name: "org_tree_page",
  components: {
    OrgView,
    ZoomController,
  },
  data() {
    return {
      isShowDetail: false,
      visible: false,
      report: {},
      data: {},
      zoom: 80,
      type: "0",
      formBuilder: initGridFormData(
        [
          {
            label: "部门编码",
            model: "deptNameEn",
            col: { span: 12 },
          },
          {
            label: "部门类型:",
            model: "deptType",
            type: "select",
            options: {
              options: [
                { label: "内部", value: "0" },
                { label: "协作单位", value: "1" },
              ],
            },
            col: { span: 12 },
          },
        ],
        { layout: "horizontal", labelWidth: 100, disabled: true }
      ),
      formBuilder1: initGridFormData(
        [
          {
            label: "邮箱",
            model: "email",
            col: { span: 12 },
          },
          {
            label: "电话:",
            model: "phone",
            col: { span: 12 },
          },
        ],
        { layout: "horizontal", labelWidth: 100, disabled: true }
      ),
    };
  },
  computed: {
    zoomHandled() {
      return this.zoom / 130;
    },
  },
  methods: {
    openModal() {
      this.visible = true;
      api.deptUserTree().then((res) => {
      this.data = {
        deptOrder: "qwer1",
        deptType: "0",
        id: "qwer1",
        label:res.data[0].label,
        parentId: "qwer1",
        type: "0",
        value: "qwer1",
        children: res.data[0].children,
      };
    });
    },
    handleMenuClick({ data, key }) {
      if (key === "detail") {
        this.isShowDetail = true;
        this.type = data.type;
        this.$nextTick(() => {
          this.$refs.formBuilder.setFieldsValue(data);
        });
      }
    },
    // 取消按钮
    handleCancel(e) {
      this.isShowDetail = false;
    },
  },
  mounted() {
    api.deptUserTree().then((res) => {
      this.data = {
        deptOrder: "qwer1",
        deptType: "0",
        id: "qwer1",
        label:res.data[0].label,
        parentId: "qwer1",
        type: "0",
        value: "qwer1",
        children: res.data[0].children,
      };
    });
  },
};
</script>
