import { post, get, downFile } from 'common/request'

export default {
  save: args => post('/staff/save', args),
  del: args => get(`/staff/delStaffAndUser?id=${args}`),
  get: args => get(`/staff/info/${args}`),
  list: args => get('/user/list', args, 'portal'),
  listCheck: args => get('/staff/queryList', args),
  // 获取字典数据
  dictData: args => get('/dict-data/getByDicKind', args),
  // 获取组织机构
  deptList: args => get('/dept/list', args),
  // 获取内部组织机构
  insideList: args => get('/dept/insideList', args),
  // 导出
  exportXls: args => downFile('/staff/exportXls', args),
  // 获取组织机构 树结构
  deptTreeList: args => get('/dept/queryAssistDeptUserTree', args),
  // user getId
  queryById: args => get(`/user/queryById?id=${args}`),

  // 部门人员树
  deptUserTree: args => get('/dept/deptUserTree', args),

  // 全组织架构
  treeList: args => get('/dept/treeList', args),
  // 部门人员树-全组织架构
  queryExternalTree: args => get('/dept/queryExternalTree', args),

  resetPassword: args => post('/user/resetPassword', args)

}
